import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  getStoreBySlugFn,
  type GetStoresBySlugResponse,
} from './ahafood/get-store-by-slug';
import { apiRoutes } from './api-routes';

export const useGetStoreBySlug = (
  slug: string,
  opts?: Omit<
    UseQueryOptions<GetStoresBySlugResponse, Error>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<GetStoresBySlugResponse, Error>({
    queryKey: [apiRoutes.ahafood.store.get_store_by_slug(slug)],
    queryFn: () => getStoreBySlugFn(slug),
    ...opts,
  });
};
