export const apiRoutes = {
  place: {
    detail: 'gplaces/web/v2/detail',
    geocode_reverse: '/wgeo/v2/reverse',
    geocode: 'wgeo/geocode',
  },
  auth: {
    link_account: '/api/v3/private/food-order/link-ahamove-account',
    activate: '/api/v3/public/user/activate',
    register: '/api/v3/public/user/register',
    verifyOtp: '/api/v3/public/user/verify_otp',
  },
  ahafood: {
    store: {
      store_items: (store_id: number) =>
        `api/v3/private/food-order/item/${store_id}`,
      store_info: (store_id: number) =>
        `api/v3/private/food-order/store/${store_id}`,
      get_promo: (store_id: number) =>
        `api/v3/private/food-order/promo/store/${store_id}`,
      update_status_promo: (promo_code: string) =>
        `api/v3/private/food-order/promo/${promo_code}/activation`,
      update_promo: (promo_code: string) =>
        `api/v3/private/food-order/promo/${promo_code}`,
      create_promo: `/api/v3/private/food-order/promo`,
      get_deal: `api/v3/public/food-order/tag`,
      get_store_by_slug: (slug: string) =>
        `/api/v3/public/food-order/store/${slug}`,
      get_store: `api/v3/public/food-order/store`,
      get_category: `/api/v3/public/food-order/category`,
    },
    update_page: (pageId: string) =>
      `/api/v3/private/food-order/page/${pageId}`,
    get_page: `/api/v3/private/food-order/page`,
    get_info: 'api/v3/private/food-order/self',
    fb_login: 'api/v3/public/food-order/social-login',
    create_store: 'api/v3/private/food-order/store',
    acquire_merchant: '/web/v1/public/ahmsites/form/acquire-merchant',
    append_page: (pageId: string) => `/v3/internal/page/facebook/${pageId}`,
  },
  cms: {
    homeBanner: {
      list: '/api/home-banner?populate[items][populate]=%2A',
    },
  },
};
