import { type AxiosRequestConfig, httpClient } from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';

export type GetUserInfoResponse = {
  _id: string;
  email: string;
  status: string;
  name: string;
  facebook_id: string;
  facebook_token: string;
  create_time: number;
  last_login: number;
  ahamove_id: string;
};

export const getUserInfoFn = async (config: AxiosRequestConfig = {}) => {
  const response = await httpClient.get<GetUserInfoResponse>(
    apiRoutes.ahafood.get_info,
    config
  );

  return response?.data;
};
