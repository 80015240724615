'use client';

import { JSFunction } from '@ahamove/js-function';
import {
  getAccessToken,
  LOCAL_STORAGE_KEY__FINGERPRINT,
  setCookie,
} from '@ahm/api-wrappers-core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useSearchParams } from 'next/navigation';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { toast } from 'sonner';
import type { GetUserInfoResponse } from '@/api/ahafood/fb-get-user-info-fn';
import type { Page } from '@/api/ahafood/get-page-fn';
import { useFBLoginMerchant } from '@/api/use-facebook-login';
import { useGetPages } from '@/api/use-get-page';
import { useGetUserInfo } from '@/api/use-get-user-info';
import { usePostFacebookPage } from '@/api/use-upset-page';
import { ErudaLoader } from '@/components/eruda-loader';
import { publicEnv } from '@/config/public-env.config.mjs';
import { useIsMobile } from '@/lib/is-mobile';

export interface PageInfo {
  data: PageData[];
  paging: Paging;
}

export interface PageData {
  map: any;
  access_token: string;
  category: string;
  category_list: CategoryList[];
  name: string;
  id: string;
  tasks: string[];
}

export interface CategoryList {
  id: string;
  name: string;
}

export interface Paging {
  cursors: Cursors;
}

export interface Cursors {
  before: string;
  after: string;
}

interface AuthState {
  isWebinapp: boolean;
  accessToken: string | null;
  user: GetUserInfoResponse | null;
  pages: Page[];
  updatePages: () => void;
  status: 'authorized' | 'unauthorized' | 'loading';
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    getAccessToken()
  );

  const [pages, setPages] = useState<Page[]>([]);
  const [user, setUser] = useState<GetUserInfoResponse | null>(null);
  const [status, setStatus] = useState<AuthState['status']>('loading');
  const searchParams = useSearchParams();
  const sessionToken = searchParams.get('session_token');
  const webinappParam = !!searchParams.get('webinapp');
  const hasLoggedIn = useRef(false);

  const isMobile = useIsMobile();

  const isWebinapp = useMemo(() => {
    return !!webinappParam && isMobile;
  }, [webinappParam, isMobile]);

  const { data: userData, error: userError } = useGetUserInfo({
    queryKey: ['userInfor', accessToken],
    enabled: !!accessToken,
  });

  const { mutate: upsetPage } = usePostFacebookPage({
    onSuccess: () => {
      toast.success('Cập nhật thông tin trang thành công');
      updatePages();
    },
    onError: () => {
      toast.error('Lỗi khi xử lý dữ liệu trang, vui lòng thử lại sau');
    },
  });

  const { data: pageData, refetch: refetchPageData } = useGetPages({
    enabled: status === 'authorized',
  });

  const updatePages = useCallback(() => {
    void refetchPageData();
  }, [refetchPageData]);

  // const handleUpsetPages = useCallback(
  //   async (pages: PageData[]) => {
  //     const upsetPromises = pages.map((page) => {
  //       const upsetPageData = {
  //         page_id: page.id,
  //         page_access_token: page.access_token,
  //         page_name: page.name,
  //       };
  //       return upsetPage(upsetPageData);
  //     });

  //     try {
  //       const results = await Promise.allSettled(upsetPromises);
  //       results.forEach((result, index) => {
  //         if (result.status === 'rejected') {
  //           toast.error(
  //             `Lỗi cập nhật trang ${pages[index].name}: ${result.reason}`
  //           );
  //         }
  //       });
  //       setStatus('authorized');
  //     } catch (error) {
  //       toast.error('Đã xảy ra lỗi khi cập nhật trang. Vui lòng thử lại sau.');
  //       setStatus('unauthorized');
  //     }
  //   },
  //   [upsetPage]
  // );

  useEffect(() => {
    const loadFingerprint = async () => {
      // eslint-disable-next-line import/no-named-as-default-member
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      localStorage.setItem(LOCAL_STORAGE_KEY__FINGERPRINT, fingerprint);
    };

    if (typeof window !== 'undefined') {
      void loadFingerprint();
    }
  }, []);

  const { mutate: mutateFBLogin } = useFBLoginMerchant({
    onSuccess(res) {
      setCookie('token', res.access_token);
      setAccessToken(res.access_token);
      // updatePages();
      toast.success('Đăng nhập thành công');
      clearDataFromUrl(['session_token']);
    },
    onError() {
      toast.error('Không thể đăng nhập ở thời điểm này, vui lòng thử lại sau.');
      setStatus('unauthorized');
      clearDataFromUrl(['session_token']);
    },
  });

  useEffect(() => {
    if (userData) {
      setStatus('authorized');
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } else if (userError ?? !accessToken) {
      setStatus('unauthorized');
      setUser(null);
      localStorage.removeItem('user');
      setCookie('token', '');
      setAccessToken(null);
    }
  }, [userData, userError, accessToken]);

  useEffect(() => {
    if (!userData) {
      setStatus('unauthorized');
    }
  }, [userData]);

  useEffect(() => {
    if (pageData) {
      setPages(pageData.pages);
    }
  }, [pageData]);

  function clearDataFromUrl(fields: string[]) {
    try {
      const url = new URL(window.location.href);
      fields.forEach((field) => url.searchParams.delete(field));
      window.history.replaceState({}, '', url.toString());
    } catch (error) {
      console.error('Failed to clear error code and message from url', error);
    }
  }

  const login = useCallback(() => {
    const scopes = [
      'email',
      'pages_messaging',
      'pages_show_list',
      'pages_read_user_content',
      'pages_manage_ads',
      'pages_manage_metadata',
      'pages_manage_engagement',
    ];

    const fbLoginUrl = `https://www.facebook.com/${publicEnv.NEXT_PUBLIC_FACEBOOK_API_VERSION}/dialog/oauth?client_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}/api/auth/callback/facebook&scope=${scopes.join(',')}&response_type=code&auth_type=rerequest&state={}`;
    window.open(fbLoginUrl, '_self');
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('page');
    localStorage.removeItem('isupset');
    setCookie('token', '');
    setAccessToken(null);
    setUser(null);
    setStatus('unauthorized');
    clearDataFromUrl(['session_token']);
  }, []);

  useEffect(() => {
    if (sessionToken && !hasLoggedIn.current) {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        // User is already logged in, call upsetPage
        upsetPage({ facebook_token: sessionToken });
      } else {
        // New login, call mutateFBLogin
        mutateFBLogin({
          facebook_token: sessionToken,
        });
      }
      hasLoggedIn.current = true;
      clearDataFromUrl(['session_token']);
    } else if (!sessionToken && !accessToken) {
      setStatus('unauthorized');
    }
  }, [sessionToken, mutateFBLogin, upsetPage, accessToken]);

  useEffect(() => {
    if (isWebinapp) {
      JSFunction.call({ name: 'hide_toolbar' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebinapp]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        status,
        login,
        logout,
        isWebinapp,
        pages,
        updatePages,
      }}
    >
      {children}
      <ErudaLoader
        enabled={
          publicEnv.NEXT_PUBLIC_ENABLE_MOBILE_CONSOLE === 'true' && isWebinapp
        }
      />
    </AuthContext.Provider>
  );
};
