'use client';

import { publicHttpClient } from '@ahm/api-wrappers-core';
import { cn, session } from '@ahm/common-helpers';
import { X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { usePathname } from '@/i18n/routing';
import { useAuth } from '@/providers/auth-provider';
import { CITIES } from '@/store';

type FormInputs = {
  restaurantName: string;
  phoneNumber: string;
  cityID: string;
  status: string;
  account: string;
};

const SCRIPT_URLS = {
  DAD: 'https://script.google.com/macros/s/AKfycbxhEwW9RTsUnutRvLCgMtwmSc4tTCz7ZyeeBrQZ1H27Q0mgwU-TA9Mb0vsxSGtsFzcJGA/exec',
  SGN: 'https://script.google.com/macros/s/AKfycbwwGcq5JOxagJDccKHoz6DLWQbN4t8LZRWwg-OJfBGd9Bm7DQ9OeQoTtoSfVsX-Iy5O/exec',
  HAN: 'https://script.google.com/macros/s/AKfycbxj2qu-E6CM9jpYmAHUlRln26JkHfjebGbldyLkCb4MRUTQt0hgOFnboxUhPW3UohaB/exec',
};

export default function RestaurantRegistrationForm() {
  const pathname = usePathname();
  const targetRef = useRef<HTMLDivElement>(null);
  const [hasScrolledToTarget, setHasScrolledToTarget] = useState(false);
  const { isWebinapp } = useAuth();

  const handleScroll = () => {
    const target = targetRef.current;

    if (!target) return;

    const targetPosition = target.getBoundingClientRect().top + window.scrollY; // Get the absolute position
    const currentScroll = window.scrollY + window.innerHeight;

    // Check if the user has scrolled past the target's position
    if (currentScroll > targetPosition) {
      setHasScrolledToTarget(true);
    } else {
      setHasScrolledToTarget(false);
    }
  };

  useEffect(() => {
    const target = targetRef.current;

    if (!target) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // When the target is in view, check scroll position
          handleScroll();
        } else {
          setHasScrolledToTarget(false);
        }
      },
      {
        root: null, // Viewport as the root
        threshold: 0.5, // Trigger as soon as any part of the target is visible
      }
    );

    observer.observe(target);

    // Add scroll listener to check scroll position
    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.unobserve(target);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [disabledSticky, setDisabledSticky] = useState<boolean>(
    !!session.getItem('acquire_visited') || true
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const val = !!session.getItem('acquire_visited');
    setDisabledSticky(val);
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit = async (data: FormInputs) => {
    setIsSubmitting(true);
    try {
      const scriptUrl = SCRIPT_URLS[data.cityID as keyof typeof SCRIPT_URLS];
      if (!scriptUrl) {
        throw new Error('Ahafood chưa hỗ trợ khu vực này');
      }

      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) =>
        formData.append(key, value)
      );

      const res = await publicHttpClient.post(scriptUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const result = res.data;

      if (result.status === 'success') {
        toast.success(result.message);
        reset();
        handleHideAcquireForm();
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error instanceof Error ? error.message : 'Có lỗi xảy ra');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleHideAcquireForm = () => {
    setDisabledSticky(true);
    session.setItem('acquire_visited', 'true');
  };

  if (
    !pathname.includes('store') ||
    pathname.includes('store/') ||
    pathname.includes('merchant')
  ) {
    return null;
  }

  return (
    <section
      ref={targetRef}
      className={cn('min-h-[284px] bg-secondary-10 md:px-6 md:py-16')}
    >
      <div
        className={cn(
          'relative z-[5] mx-auto max-w-screen-md space-y-3 bg-gradient-to-r from-secondary-70 to-secondary-50 p-4 md:rounded-xl md:p-8',
          {
            'pb-10': isWebinapp && !hasScrolledToTarget && !disabledSticky,
            'fixed bottom-0 left-0 w-full shadow-8 md:bottom-4 md:left-1/2 md:-translate-x-1/2 md:px-6 md:py-4':
              !hasScrolledToTarget && !disabledSticky,
          }
        )}
      >
        {!hasScrolledToTarget && !disabledSticky ? (
          <button
            onClick={handleHideAcquireForm}
            className="absolute right-2 top-2 flex size-6 items-center rounded-full bg-white p-1 ring-muted md:right-4 md:top-4"
          >
            <X className="size-4" />
          </button>
        ) : null}
        <div className="!mt-0 w-full text-center text-xl font-bold text-white md:text-2xl">
          <span>Nhà Hàng muốn tư vấn đăng ký</span>
          <span className="text-primary-40 drop-shadow-md">
            {' '}
            AhaFood.AI CHATBOT
          </span>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-row items-start justify-between space-y-4"
        >
          <div className="grid grid-cols-1 gap-2 md:grid-cols-10">
            <div className="col-span-1 w-full md:col-span-3">
              <Label htmlFor="restaurantName" className="sr-only">
                Tên nhà hàng
              </Label>
              <Input
                id="restaurantName"
                type="text"
                className="md:h-12"
                placeholder="Tên quán"
                {...register('restaurantName', {
                  required: 'Tên quán là bắt buộc',
                })}
              />
              {errors.restaurantName && (
                <p className="mt-1 font-medium text-red-50">
                  {errors.restaurantName.message}
                </p>
              )}
            </div>
            <div className="col-span-1 w-full md:col-span-3">
              <Label htmlFor="phoneNumber" className="sr-only">
                Số Điện thoại
              </Label>
              <Input
                id="phoneNumber"
                type="tel"
                className="md:h-12"
                placeholder="Số Điện thoại"
                {...register('phoneNumber', {
                  required: 'Số điện thoại là bắt buộc',
                  pattern: {
                    value: /^\d{10}$/,
                    message: 'Vui lòng nhập số điện thoại hợp lệ (10 chữ số)',
                  },
                })}
              />
              {errors.phoneNumber && (
                <p className="mt-1 font-medium text-red-50">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <div className="col-span-1 w-full md:col-span-2">
              <Controller
                name="cityID"
                control={control}
                rules={{ required: 'Vui lòng chọn khu vực' }}
                render={({ field }) => (
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger
                      id="city_id"
                      className="w-full rounded-sm border border-neutral-30 bg-white px-3 py-2 text-neutral-60 shadow-sm md:h-12"
                    >
                      <SelectValue placeholder="Chọn khu vực" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {CITIES.map((city) => (
                          <SelectItem key={city.value} value={city.value}>
                            {city.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                )}
              />
              {errors.cityID && (
                <p className="mt-1 font-medium text-red-50">
                  {errors.cityID.message}
                </p>
              )}
            </div>
            <Button
              type="submit"
              className="mt-2 h-12 w-full bg-primary-50 font-bold text-white first-line:col-span-1 hover:bg-primary-60 md:col-span-2 md:mt-0"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Đang gửi...' : 'Gửi'}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
