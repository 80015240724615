'use client';

import { FacebookWhite } from '@ahamove/icons';
import {
  ChevronDown,
  CircleUserRound,
  Facebook,
  Loader2,
  LogOut,
} from 'lucide-react';
import Link from 'next/link';
import HeaderFilter from './store/header-filter';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ROUTES } from '@/config/router';
import { usePathname, useRouter } from '@/i18n/routing';
import { useAuth } from '@/providers/auth-provider';

export default function AuthButton() {
  const pathname = usePathname();
  const { status, login, logout } = useAuth();
  const router = useRouter();
  const handleLogout = () => {
    logout();
    router.push('/');
  };

  const isLoading = status === 'loading';

  if ([ROUTES.search, ROUTES.store].includes(pathname)) return <HeaderFilter />;

  return (
    <div className="w-full md:max-w-fit">
      {status === 'authorized' ? (
        <div className="flex items-center justify-end space-x-6">
          <DropdownMenu>
            <DropdownMenuTrigger
              asChild
              className="text-neutral-50 hover:text-neutral-70 focus:text-neutral-70 active:text-neutral-70"
            >
              <div className="flex py-0 md:py-4">
                <CircleUserRound className="size-6 md:size-8" />
                {/* <span className="w-full max-w-96 self-center truncate bg-transparent text-xl font-bold">
                  Hi, {user?.name}
                </span> */}
                <ChevronDown className="ml-2 size-6 flex-none md:size-8" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem className="font-medium">
                <Link
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => {}}
                  passHref
                  href="/merchant/profile"
                  className="flex items-center"
                >
                  <CircleUserRound className="mr-2 size-4" />
                  Hồ sơ người dùng
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem className="font-medium">
                <Link
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => {}}
                  passHref
                  href="/merchant/profile"
                  className="flex items-center"
                >
                  <Facebook className="mr-2 size-4" />
                  Trang của bạn
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleLogout} className="font-medium">
                <LogOut className="mr-2 size-4" />
                Đăng xuất
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : (
        <Button
          variant="default"
          className="h-10 w-full min-w-40 rounded text-xl font-bold md:h-[60px] md:min-w-52"
          onClick={login}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 size={24} className="animate-spin text-neutral-50" />
          ) : (
            <>
              <FacebookWhite className="mr-2 size-6" /> Đăng nhập
            </>
          )}
        </Button>
      )}
    </div>
  );
}
