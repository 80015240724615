'use client';

import { debounce } from '@ahm/common-helpers';
import { useSearchParams } from 'next/navigation';
import { useCallback, useMemo, useOptimistic, useTransition } from 'react';
import { ROUTES } from '@/config/router';
import { usePathname, useRouter } from '@/i18n/routing';

export const filterConfig = {
  q: { type: 'string' },
  nearby: { type: 'boolean' },
  promotion: { type: 'boolean' },
  campaign_id: { type: 'string' },
  category: { type: 'string' },
  freeship: { type: 'boolean' },
  lat: { type: 'string' },
  lng: { type: 'string' },
  banner: { type: 'string' },
  webinapp: { type: 'string' },
  special_request: { type: 'string' },
  token: { type: 'string' },
};

const paramsToRemoveForQAndCategory = [
  'campaign_id',
  'category',
  'freeship',
  'promotion',
];
const paramsToRemoveForCampaignId = ['q', 'category'];

export function useSearchFilters() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();

  const hasLocationParams = useMemo(() => {
    return searchParams.has('lat') && searchParams.has('lng');
  }, [searchParams]);

  const initialFilters = useMemo(() => {
    const filters: Record<string, string> = {};
    Array.from(searchParams.entries()).forEach(([key, value]) => {
      if (key in filterConfig) {
        filters[key] = value;
      }
    });
    return filters;
  }, [searchParams]);

  const [optimisticFilters, setOptimisticFilters] = useOptimistic(
    initialFilters,
    (
      state,
      action: { type: 'add' | 'remove' | 'clear'; key?: string; value?: string }
    ) => {
      switch (action.type) {
        case 'add':
          if (action.key === 'q' || action.key === 'category') {
            return {
              ...Object.fromEntries(
                Object.entries(state).filter(
                  ([key]) => !paramsToRemoveForQAndCategory.includes(key)
                )
              ),
              [action.key]: action.value!,
            };
          } else if (action.key === 'campaign_id') {
            return {
              ...Object.fromEntries(
                Object.entries(state).filter(
                  ([key]) => !paramsToRemoveForCampaignId.includes(key)
                )
              ),
              [action.key]: action.value!,
            };
          }
          return { ...state, [action.key!]: action.value! };
        case 'remove':
          return Object.fromEntries(
            Object.entries(state).filter(([key]) => key !== action.key)
          );
        case 'clear':
          return Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              ['lat', 'lng', 'token', 'webinapp'].includes(key)
            )
          );
        default:
          return state;
      }
    }
  );

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      if (name === 'q' || name === 'category') {
        paramsToRemoveForQAndCategory.forEach((param) => params.delete(param));
      } else if (name === 'campaign_id') {
        paramsToRemoveForCampaignId.forEach((param) => params.delete(param));
      }
      params.set(name, value);
      return params.toString();
    },
    [searchParams]
  );

  const addFilter = useCallback(
    (key: string, value: string) => {
      startTransition(() => {
        setOptimisticFilters({ type: 'add', key, value });
        const queryString = createQueryString(key, value);
        const targetPath = hasLocationParams ? pathname : ROUTES.search;
        console.log(queryString);

        router.push(`${targetPath}?${queryString}`, { scroll: true });
      });
    },
    [
      createQueryString,
      hasLocationParams,
      pathname,
      router,
      setOptimisticFilters,
    ]
  );

  const removeFilter = useCallback(
    (...keys: string[]) => {
      startTransition(() => {
        keys.forEach((key) => setOptimisticFilters({ type: 'remove', key }));
        const params = new URLSearchParams(searchParams);
        keys.forEach((key) => params.delete(key));
        const targetPath = hasLocationParams ? pathname : ROUTES.search;
        router.push(`${targetPath}?${params.toString()}`, { scroll: true });
      });
    },
    [searchParams, router, hasLocationParams, pathname, setOptimisticFilters]
  );

  const clearAllFilters = useCallback(() => {
    startTransition(() => {
      setOptimisticFilters({ type: 'clear' });
      const params = new URLSearchParams(searchParams);

      searchParams.forEach((_, key) => {
        if (!['lat', 'lng', 'token', 'webinapp'].includes(key)) {
          params.delete(key);
        }
      });

      const targetPath = hasLocationParams ? pathname : ROUTES.search;
      router.push(`${targetPath}?${params.toString()}`, { scroll: true });
    });
  }, [searchParams, hasLocationParams, pathname, router, setOptimisticFilters]);

  const debouncedAddFilter = useMemo(
    () => debounce(addFilter, 300),
    [addFilter]
  );

  return {
    addFilter: debouncedAddFilter,
    removeFilter,
    clearAllFilters,
    activeFilters: optimisticFilters,
    isPending,
  };
}
