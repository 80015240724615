import type { Address } from '@ahm/api-wrappers-core';
import { session } from '@ahm/common-helpers';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { logger } from './logger';
import { createSelectors } from './selectors';

export const CITIES = [
  { label: 'Hà Nội', value: 'HAN' },
  { label: 'Đà Nẵng', value: 'DAD' },
  { label: 'Hồ Chí Minh', value: 'SGN' },
];

export type AiStoreOrderState = {
  isOpenMenu: boolean;
  city_id: string | null;
  address: Address | null;
  isLoading: boolean;
};

export interface AiStoreOrderActions {
  setIsOpenMenu: (isOpen: boolean) => void;
  setCityId: (city_id: string) => void;
  setAddress: (address: Address | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const AHAFOOD_STORE_NAME = 'ai.store';

export const ahafoodStore = create<
  AiStoreOrderState & AiStoreOrderActions,
  [
    ['zustand/devtools', AiStoreOrderState & AiStoreOrderActions],
    ['zustand/persist', Pick<AiStoreOrderState, 'city_id'>],
  ]
>(
  logger(
    devtools(
      persist(
        (set) => ({
          city_id: null,
          address: null,
          isLoading: false,
          isOpenMenu: false,
          setIsOpenMenu: (isOpen) => set({ isOpenMenu: isOpen }),
          setIsLoading: (isLoading: boolean) => set({ isLoading }),
          setAddress: (address: Address) => set({ address }),
          setCityId: (city_id: string) => set({ city_id }),
        }),
        {
          name: AHAFOOD_STORE_NAME,
          storage: createJSONStorage(() => session),
          partialize: (state) => ({
            city_id: state.city_id,
          }),
        }
      )
    )
  )
);

export const useAiFoodOrderStore = createSelectors(ahafoodStore);
