export const ROUTES = {
  home: '/',
  store: '/store',
  search: '/search',
  sentryExamplePage: '/sentry-example-page',
  merchant: {
    root: '/merchant',
    profile: '/merchant/profile',
    stores: '/merchant/stores',
    store: (storeId: number) => `/merchant/stores/${storeId}`,
  },
};
