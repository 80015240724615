'use client';
import { cn } from '@ahm/common-helpers';
import type { HTMLAttributes, ReactNode } from 'react';
import MainFooter from './main-footer';
import MainHeader from './main-header';
import classes from './main-layout.module.css';
import MainNavbar from './main-navbar';
import RestaurantRegistrationForm from '@/app/[locale]/(main)/store/components/items/store-contact';
import { OrderButton } from '@/app/[locale]/(main)/store/components/order-button';
import { usePathname } from '@/i18n/routing';

export type MainLayoutProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  withNavbar?: boolean;
};

export default function MainLayout({
  children,
  className,
  withNavbar,
  ...props
}: MainLayoutProps) {
  const pathname = usePathname();
  const isStorePage = pathname.includes('store/');
  return (
    <>
      <style>
        {`
    :root {
      --main-layout-header-height: 7.125;
    }
    @media (min-width: 768px) {
      :root {
        --main-layout-header-height: 7.125;
      }
    }
  `}
      </style>
      {withNavbar ? (
        <style>{`
          :root {
      --main-layout-navbar-width: 18.75rem;
    }
      `}</style>
      ) : null}
      <MainHeader />
      <main
        className={cn(
          'bg-accent',
          className,
          { [classes.main]: !isStorePage },
          { [classes.storeMain]: isStorePage }
        )}
        {...props}
      >
        {withNavbar ? <MainNavbar /> : null}
        {children}
      </main>
      <OrderButton />
      <RestaurantRegistrationForm />
      <MainFooter />
    </>
  );
}
