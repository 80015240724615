import {
  publicHttpClient,
  type AxiosRequestConfig,
} from '@ahm/api-wrappers-core';
import { apiRoutes } from '../api-routes';
import type { Store } from './get-store-fn';

export interface GetStoresBySlugResponse {
  store: Store;
}

export const getStoreBySlugFn = async (
  slug: string,
  config: AxiosRequestConfig = {}
) => {
  const response = await publicHttpClient.get<GetStoresBySlugResponse>(
    apiRoutes.ahafood.store.get_store_by_slug(slug),
    {
      ...config,
    }
  );

  return response?.data;
};
