'use client';

import { JSFunction } from '@ahamove/js-function';
import { Loader2 } from 'lucide-react';
import { useParams } from 'next/navigation';
import { useRef } from 'react';
import { toast } from 'sonner';
import { useGetStoreBySlug } from '@/api/use-get-store-by-slug';
import { Button } from '@/components/ui/button';
import { usePathname } from '@/i18n/routing';
import { useAuth } from '@/providers/auth-provider';

interface OrderButtonProps {
  className?: string;
}

export function OrderButton({ className }: OrderButtonProps) {
  const { slug } = useParams();
  const pathname = usePathname();
  const isStorePage = pathname.includes('store/');
  const targetRef = useRef<HTMLDivElement>(null);
  const { data: store, isLoading } = useGetStoreBySlug(slug as string, {
    enabled: !!slug,
  });
  const { isWebinapp } = useAuth();

  const handleSendMessage = (pageId: number) => {
    const baseUrl = `http://m.me/${pageId}?text=%2Fai`;
    const url = `${baseUrl}&ref=${isWebinapp ? 'app' : 'web-slug'}`;
    if (!pageId) {
      toast.error('Có lỗi khi đặt đơn, vui lòng thử lại.');
    }
    if (isWebinapp) {
      try {
        JSFunction.call({
          name: 'openPage',
          body: url,
        });
      } catch (error) {
        window.open(url);
      }
    } else {
      window.open(url);
    }
  };

  if (!isStorePage) {
    return null;
  }

  return (
    <section className="md:hidden" ref={targetRef}>
      <div className="fixed bottom-0 left-0 w-full bg-white shadow-8-revert">
        <div className="px-4 pb-8 pt-4">
          <Button
            disabled={isLoading}
            size="lg"
            className={`w-full py-6 text-lg font-semibold ${className}`}
            onClick={() =>
              store?.store.page_id && handleSendMessage(store.store.page_id)
            }
          >
            {isLoading ? (
              <Loader2 size={20} className="mr-2 animate-spin" />
            ) : null}
            Đặt món ngay
          </Button>
        </div>
      </div>
    </section>
  );
}
