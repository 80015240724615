'use client';

import { ArrowLeft } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useGetDeal } from '@/api/use-get-deal';
import { useSearchFilters } from '@/api/use-search-filters';
import HeaderSearch from '@/components/store/header-search';
import { Button } from '@/components/ui/button';
import { ROUTES } from '@/config/router';
import { Link } from '@/i18n/routing';
import { useAiFoodOrderStore } from '@/store/use-ai-store';

interface BannerInfo {
  title_vi_vn: string;
}

export default function SearchHeaderMobile() {
  const { activeFilters } = useSearchFilters();
  const [bannerInfo, setBannerInfo] = useState<BannerInfo | null>(null);
  const cityId = useAiFoodOrderStore.use.city_id();
  const banner_id = activeFilters.banner;
  const isNewStore = activeFilters.special_request;

  const { data: deals } = useGetDeal(
    {
      city_id: cityId ?? '',
      skip: 0,
      limit: 25,
    },
    { enabled: !!cityId && !!banner_id }
  );

  useEffect(() => {
    if (deals?.tags && banner_id) {
      const bannerId = banner_id;
      const banner = deals.tags.find((tag) => tag._id === parseInt(bannerId));

      if (banner) {
        setBannerInfo({
          title_vi_vn: banner.title_vi_vn,
        });
      } else {
        setBannerInfo(null);
      }
    } else {
      setBannerInfo(null);
    }
  }, [deals, banner_id]);

  const backHref = useMemo(() => {
    if (activeFilters.webinapp && activeFilters.token) {
      const params = new URLSearchParams({
        webinapp: activeFilters.webinapp,
        token: activeFilters.token,
      });
      return `${ROUTES.store}?${params.toString()}`;
    }
    return ROUTES.store;
  }, [activeFilters.token, activeFilters.webinapp]);

  return (
    <div className="container sticky top-0 z-50 mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-4 bg-white p-4 sm:flex-row md:h-24 md:gap-6 md:px-6">
      <div className="m-0 flex w-full flex-nowrap justify-end md:space-x-3">
        <Link href={backHref} passHref>
          <Button
            variant="link"
            size="lg"
            className="-ml-4 h-12 px-4 text-xl font-bold text-neutral-70 md:h-16"
          >
            <ArrowLeft className="size-6 md:mr-2" />
            <span className="hidden md:inline-block">Quay lại</span>
          </Button>
        </Link>
        {isNewStore || bannerInfo ? (
          <h2 className="mr-14 w-full content-center text-center text-xl font-bold">
            {isNewStore ? 'Quán mới' : bannerInfo?.title_vi_vn}
          </h2>
        ) : (
          <HeaderSearch />
        )}
      </div>
    </div>
  );
}
