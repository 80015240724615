'use client';

import { AhafoodLogo } from '@ahamove/icons/order';
import { JSFunction } from '@ahamove/js-function';
import { cn } from '@ahm/common-helpers';
import { ArrowLeft, X } from 'lucide-react';
import classes from './main-layout.module.css';
import SearchHeaderMobile from '@/app/[locale]/(main)/store/components/items/search-header-mobile';
import LoginButton from '@/components/fb-login-btn';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { publicEnv } from '@/config/public-env.config.mjs';
import { ROUTES } from '@/config/router';
import { Link, usePathname } from '@/i18n/routing';
import { useIsMobile } from '@/lib/is-mobile/use-is-mobile';
import { useAuth } from '@/providers/auth-provider';

export default function MainHeader() {
  const pathname = usePathname();
  const { isWebinapp } = useAuth();
  const isMobile = useIsMobile();
  if (pathname.includes(ROUTES.store + '/')) {
    return (
      <header className="fixed inset-x-0 top-0 z-[51] items-center bg-white">
        <div className="mx-auto flex max-w-screen-lg flex-row">
          <Link href={ROUTES.store} passHref>
            <Button
              variant="link"
              size="lg"
              className="h-12 px-4 text-xl font-bold text-neutral-70 md:h-16"
            >
              <ArrowLeft className="mr-2 size-7" />
              <span className="hidden md:block">Quay lại</span>
            </Button>
          </Link>
          <AhafoodLogo className="mr-16 h-10 w-full" />
        </div>
      </header>
    );
  }
  return (
    <>
      {isWebinapp && pathname != ROUTES.search ? (
        <div className="fixed inset-x-0 top-0 z-[51] flex w-full items-center justify-center gap-4 border-b border-neutral-15 bg-white py-1 text-center text-base font-bold">
          <Button
            variant="ghost"
            className="absolute left-1 top-1/2 size-10 flex-none -translate-y-1/2 p-2"
            onClick={() => {
              JSFunction.call({ name: 'close' });
            }}
          >
            <X className="size-6 flex-none" />
          </Button>
          <Link
            className="flex flex-none items-center justify-start"
            href={isWebinapp ? '' : ROUTES.home}
          >
            <AhafoodLogo className="h-10 w-36" />
            <h1 className="sr-only">{publicEnv.NEXT_PUBLIC_APP_TITLE}</h1>
          </Link>
        </div>
      ) : null}
      <header
        className={cn(classes.header, {
          'pt-12': isWebinapp && pathname !== ROUTES.search,
          fixed: pathname !== ROUTES.store,
          'static md:fixed': pathname === ROUTES.store,
          'bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60':
            pathname !== ROUTES.search,
          'bg-background': pathname === ROUTES.search,
        })}
      >
        {pathname === ROUTES.search && isMobile ? (
          <SearchHeaderMobile />
        ) : (
          <div className="container mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-4 p-4 sm:flex-row md:h-24 md:gap-6 md:px-6">
            <div
              className={cn(
                'flex w-full flex-1 flex-row flex-nowrap items-center',
                {
                  hidden: isWebinapp,
                }
              )}
            >
              <Link
                className="mr-4 flex flex-none items-center justify-start md:mr-6"
                href={ROUTES.home}
              >
                <AhafoodLogo className="h-10 w-36 sm:h-16 sm:w-[259px]" />
                <h1 className="sr-only">{publicEnv.NEXT_PUBLIC_APP_TITLE}</h1>
              </Link>
              <Separator orientation="vertical" className="h-5" />
              <Link passHref href={ROUTES.store}>
                <Button
                  variant="link"
                  className={cn(
                    'mt-1 h-9 w-full rounded bg-transparent px-4 py-0 text-lg font-bold !no-underline hover:bg-transparent md:h-16 md:px-6 md:text-xl',
                    {
                      'text-neutral-50 hover:text-neutral-70':
                        pathname !== '/store',
                      'text-primary-40': pathname === '/store',
                    }
                  )}
                >
                  Đặt món ăn
                </Button>
              </Link>
            </div>
            <div className="m-0 flex w-full flex-nowrap justify-end sm:w-3/5">
              <LoginButton />
            </div>
          </div>
        )}
      </header>
    </>
  );
}
